import React from 'react';
import Image from 'next/image';
import posthog from 'posthog-js';
import styled from 'styled-components';
import StyledButton from '@makeship/core/lib/components/Button';
import { P1Styles, P2 } from '../../../Typography';
import Container from '../../../Container';
import config from '../../../../../config.json';

const { routes } = config;

const MastheadWrapper = styled.div`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-image: url('/assets/menace/menace_masthead_bg_mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-image: url('/assets/menace/menace_masthead_bg_tablet.png');
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    background-image: url('/assets/menace/menace_masthead_bg_desktop.png');
  }
`;

const MastheadContainer = styled(Container)`
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 16px;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    width: 80%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.maxContent}px) {
    width: 70%;
  }
`;

const DesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: block;
    width: 500px;
    height: 500px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    display: none;
  }
`;

const LargeDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    display: block;
    width: 548px;
    height: 548px;
  }
`;

const SmallDesktopPlushieImageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
    width: 424px;
    height: 424px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`;

const MobilePlushieImageWrapper = styled.div`
  width: 282px;
  height: 282px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  background: ${({ theme }) => `linear-gradient(180deg, ${theme.colors.neutral7} 0%, #2E2E2E 100%)`};
  display: flex;
  padding: 24px 16px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  max-width: 328px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 400px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 480px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    max-width: 420px;
    padding: 42px 32px;
    justify-content: space-between;
    height: 410px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: 398px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    max-width: 468px;
  }
`;

const InfoLogoWrapper = styled.div`
  display: block;
  max-width: 240px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    max-width: 332px;
  }
`;

const InfoText = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${P1Styles}
    color: ${({ theme }) => theme.colors.neutral1};
  }
`;

const InfoStyledButton = styled(StyledButton.Tertiary)`
  color: ${({ theme }) => theme.colors.neutral7};
  border: transparent;
  padding: 14px 24px;
  width: 280px;
  &:active {
    border: transparent;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 336px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 280px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const InfoContentWrapper = styled.div``;

const MenaceMasthead: React.FC = () => (
  <MastheadWrapper>
    <MastheadContainer>
      <InfoWrapper>
        <InfoLogoWrapper>
          <Image src="/assets/menace/menace_collection_logo.png" width="332" height="82" quality={90} />
        </InfoLogoWrapper>
        <InfoContentWrapper>
          <InfoText>
            Boo! Get into the Halloween spirit with your favorite creators&apos; newest and spookiest products. Hurry
            before they vanish forever!
          </InfoText>
        </InfoContentWrapper>

        <ButtonContainer>
          <a href={`${routes.shopMenace}`}>
            <InfoStyledButton onClick={() => posthog.capture('homepage_menace-masthead_cta_click')}>
              Shop the Menace Collection
            </InfoStyledButton>
          </a>
        </ButtonContainer>
      </InfoWrapper>
      <div>
        <LargeDesktopPlushieImageWrapper>
          <Image src="/assets/menace/menace_plush.png" width="548" height="548" />
        </LargeDesktopPlushieImageWrapper>
        <DesktopPlushieImageWrapper>
          <Image src="/assets/menace/menace_plush.png" width="500" height="500" />
        </DesktopPlushieImageWrapper>
        <SmallDesktopPlushieImageWrapper>
          <Image src="/assets/menace/menace_plush.png" width="424" height="424" />
        </SmallDesktopPlushieImageWrapper>
        <MobilePlushieImageWrapper>
          <Image src="/assets/menace/menace_plush.png" width="282" height="282" />
        </MobilePlushieImageWrapper>
      </div>
    </MastheadContainer>
  </MastheadWrapper>
);
export default MenaceMasthead;
